import React from 'react'
import './index.scss';

export const GithubIcon: React.FC<{}> = (props) => {
  return (
    <div></div>
    // <a href="https://github.com/AgoraIO-Usecase/eEducation" target="_blank" rel="noopener noreferrer" >
    // <span className="agora-github-pin">
    // </span>
    // </a>
  )
}