import React from 'react';
import VideoMarquee from '@/components/video-marquee';
import MediaBoard from '@/components/mediaboard';
import Roomboard from '@/components/roomboard';
import './small-class.scss';

export default function SmallClass() {
  var isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent);
  isMobile = isMobile != null ? isMobile : false;
  return (
    <div className="room-container">
      <VideoMarquee />
      <div className="container" style={{overflowY:(isMobile ? "auto" : "hidden")}}>
        <MediaBoard />
        <Roomboard currentActive={'media'} />
      </div>
    </div>
  )
}